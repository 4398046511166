import React from 'react';
import { Product } from '@Types/product/Product';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import ProductCard from '../card';

interface Props {
  products: Product[];
  filtering?: boolean;
  trackingHandler?: (id: string, title: string, index: number) => void;
}

const List: React.FC<Props> = ({ products, filtering, trackingHandler }) => {
  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  // const { addItem } = useCart();

  /*
  const handleAddToCart = (variant: Variant): Promise<void> => {
    // TODO: Add tracking like frontend/frontastic/tastics/snaq/products/details/index.tsx
    return addItem(variant, 1);
  };
  */

  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    //TODO check node version replaceAll is not supportet in node 14
    return url.replaceAll('200x200', size);
  };

  return (
    <div>
      <h2 className="visually-hidden">{formatProductMessage({ id: 'products', defaultMessage: 'Products' })}</h2>
      <div className="row">
        {products?.map((product, index) => (
          <div key={product.productId} className="col-12 col-sm-6 col-xl-4 d-flex mb-20">
            <ProductCard
              imageSrc={
                getImageThumb(getFiles(product.variants?.[0].images, { number: 0, srcKey: '' }), '400x400') ||
                ProductPlaceholder.src
              }
              imageAlt={product.name}
              title={product.name}
              price={product.variants?.[0].price}
              discountedPrice={product.variants?.[0].discountedPrice}
              isOnStock={!product.variants?.[0].isEcomSaleItem || !!product.variants?.[0].isOnStock}
              isNew={product.isNew}
              link={product._url || ''}
              trackingHandler={
                trackingHandler ? () => trackingHandler(product.productId, product.name, index + 1) : undefined
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
