import React, { useEffect, useState } from 'react';
import { Order } from '@Types/cart/Order';
import { useCart } from 'frontastic';

const Trustcard = () => {
  const [order, setOrder] = useState<Order | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const { getLastOrderId, removeLastOrderId, getOrderById } = useCart();

  useEffect(() => {
    if (initialized) {
      return;
    }
    if (getOrderById) {
      const orderId = getLastOrderId();
      if (orderId) {
        getOrderById(orderId).then((order) => {
          if (order && order.cartId) {
            setOrder(order);
            setInitialized(true);
            removeLastOrderId();
            // GTM event tracking
            const items = [];
            if (order?.lineItems && order.lineItems.length > 0) {
              order.lineItems.forEach((lineItem) => {
                const item = {
                  item_name: lineItem.name,
                  item_id: lineItem.variant.sku,
                  quantity: lineItem.count,
                };
                if ('brand' in lineItem) {
                  item['item_brand'] = lineItem.brand.name;
                }
                if (lineItem.discountedPrice) {
                  item['price'] = (lineItem.discountedPrice.centAmount / 100).toFixed(2);
                } else if (lineItem.price) {
                  item['price'] = (lineItem.price.centAmount / 100).toFixed(2);
                }
                if ('categories' in lineItem && lineItem.categories.length > 0) {
                  lineItem.categories
                    .sort((categoryA, categoryB) => categoryA.depth - categoryB.depth)
                    .forEach((category, index) => {
                      item[`item_category${index > 0 ? index + 1 : ''}`] = category.name;
                    });
                }
                items.push(item);
              });
            }
            let tax = null;
            if (order.taxed?.totalTax) {
              tax = (order.taxed.totalTax.centAmount / 100).toFixed(2);
            }
            let shipping = null;
            if (order.shippingInfo?.discountedPrice) {
              shipping = (order.shippingInfo.discountedPrice.centAmount / 100).toFixed(2);
            } else if (order.shippingInfo?.price) {
              shipping = (order.shippingInfo.price.centAmount / 100).toFixed(2);
            }
            if (process.env.NODE_ENV === 'production' && window.dataLayer) {
              window.dataLayer.push({ ecommerce: null });
              window.dataLayer.push({
                event: 'purchase',
                ecommerce: {
                  currency: order.sum.currencyCode,
                  value: (order.sum.centAmount / 100).toFixed(2),
                  tax: tax || undefined,
                  shipping: shipping || undefined,
                  transaction_id: orderId,
                  items: items,
                },
              });
            } else {
              console.info('GtmEvent purchase currency', order.sum.currencyCode);
              console.info('GtmEvent purchase value', (order.sum.centAmount / 100).toFixed(2));
              if (tax) {
                console.info('GtmEvent purchase tax', tax);
              }
              if (shipping) {
                console.info('GtmEvent purchase shipping', shipping);
              }
              console.info('GtmEvent purchase transaction_id', orderId);
              console.info('GtmEvent purchase items', items);
            }
            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if ('undefined' !== typeof trustbadge) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                trustbadge.remove();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                trustbadge.reInitialize();
              }
            }, 2000);
          }
        });
      }
    }
  }, [initialized, getLastOrderId, removeLastOrderId, getOrderById]);

  return order ? (
    <div id="trustedShopsCheckout" style={{ display: 'none' }}>
      <span id="tsCheckoutOrderNr">{order.cartId}</span>
      <span id="tsCheckoutBuyerEmail">{order.email}</span>
      <span id="tsCheckoutOrderAmount">{(order.sum.centAmount / 100).toFixed(2)}</span>
      <span id="tsCheckoutOrderCurrency">{order.sum.currencyCode.toUpperCase()}</span>
      <span id="tsCheckoutOrderPaymentType">{order.payments?.[0]?.paymentMethod?.toUpperCase() || 'OTHER'}</span>
      {/* TODO: Get missing info
        <span id="tsCheckoutOrderEstDeliveryDate">2020-05-24</span>
      */}
    </div>
  ) : null;
};

export default Trustcard;
