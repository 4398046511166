import React from 'react';
import { Product } from '@Types/product/Product';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import ProductCard from '../card';

interface Props extends Product {
  trackingHandler?: () => void;
}

const Tile: React.FC<Props> = ({ variants, name, _url, isNew, trackingHandler }) => {
  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  return (
    <ProductCard
      imageSrc={
        getImageThumb(getFiles(variants[0].images, { number: 0, srcKey: '' }), '400x400') || ProductPlaceholder.src
      }
      imageAlt={name}
      title={name}
      price={variants[0].price}
      discountedPrice={variants[0].discountedPrice}
      isOnStock={!variants[0].isEcomSaleItem || !!variants[0].isOnStock}
      isNew={isNew}
      link={_url}
      trackingHandler={trackingHandler}
    />
  );
};

export default Tile;
