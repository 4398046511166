import React, { useState, useEffect, useMemo, useCallback } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Category } from '@Types/product/Category';
import { Money } from '@Types/product/Money';
import { AssetSource } from '@Types/product/ProductAsset';
import { Variant } from '@Types/product/Variant';
import { Brand } from '@Types/snaq/product/Brand';
import classnames from 'classnames';
import clsx from 'clsx';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import { Navigation, Thumbs, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lightbox, Slide } from 'yet-another-react-lightbox';
// eslint-disable-next-line import/no-unresolved
import Captions from 'yet-another-react-lightbox/plugins/captions';
// eslint-disable-next-line import/no-unresolved
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
// eslint-disable-next-line import/no-unresolved
import Video from 'yet-another-react-lightbox/plugins/video';
// eslint-disable-next-line import/no-unresolved
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { default as IconCheck } from 'components/icons/check';
import { default as IconCheckOutline } from 'components/icons/check-outline';
import { default as IconChevronDown } from 'components/icons/chevron-down';
import { default as IconChevronLeft } from 'components/icons/chevron-left';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import { default as IconChevronUp } from 'components/icons/chevron-up';
import { default as IconCompareArrows } from 'components/icons/compare-arrows';
import { default as IconCopy } from 'components/icons/copy';
import { default as IconDelete } from 'components/icons/delete';
import { default as IconFlash } from 'components/icons/flash';
import { default as IconHeart } from 'components/icons/heart';
import { default as IconHeartFilled } from 'components/icons/heart-filled';
import { default as IconLocalShipping } from 'components/icons/local-shipping';
import { default as IconMail } from 'components/icons/mail';
import { default as IconPdf } from 'components/icons/pdf';
import { default as IconShare } from 'components/icons/share';
import { default as IconWhatsapp } from 'components/icons/whatsapp';
import NextJsImage from 'components/snaq-ui/products/nextJsLightboxImage';
import Slider, { SliderProps } from 'components/snaq-ui/slider';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import { sm, md, xl, xxl } from 'helpers/utils/screensizes';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import Image from 'frontastic/lib/image';
import { PageNodeTeaser } from '../../../../frontastic/tastics/snaq/categories/category-experts';
import { getAssets } from '../../../../helpers/utils/getAssets';
import { brandRouteSlug } from '../../brands/brand-list';
import MediaYoutube from '../../content/media-youtube';
import ExpertContactForms from '../../expert-contact';
import Price from '../../price';

export const technicalDetailsAttributeGroupKeyBlacklist = [
  'main',
  'erp',
  'other',
  'expert_review',
  'marketing_information',
];
export const technicalDetailsAttributeGroupByKey = [
  'technical_details',
  'camera_properties',
  'image_video_quality',
  'lens_properties',
  'display_properties',
  'audio_properties',
  'light_properties',
  'tripods_stability',
  'storage_properties',
  'battery_power',
  'connectivity_ports',
  'operation_control',
  'software',
  'bags_suitcase',
  'functions',
  'integrated_components',
  'expansion_options',
  'security_certification',
  'dimensions',
  'operating_environment',
  'other_properties',
];
const technicalDetailsAttributeActiveByKey = ['technical_details', 'camera_properties'];

export interface Props {
  product: UIProduct;
  associatedVariantsProducts: UIProduct[];
  associatedCompatibilityProducts: UIProduct[];
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  comparisonSkus: string[] | null;
  onToggleComparison: (sku: string) => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
  minOrderAmount: number;
  maxOrderAmount: number;
  maxOrderAmountMargin: number;
  breadcrumbCategory?: Category | null;
  expertTeaser?: PageNodeTeaser;
}
export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  price?: Money;
  discountedPrice?: Money;
  grossPrice?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  isOnStock?: boolean;
  isNew?: boolean;
  _url: string;
  brand?: Brand;
  isEcomSaleItem?: boolean;
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
export interface UIDetail {
  key: string;
  name: string;
  items: UIDetailItem[];
}
export interface UIDetailItem {
  key: string;
  label: string;
  value: string;
}
export interface UIAsset {
  key: string;
  source: AssetSource;
  name: string;
  description?: string;
  tags: string[];
}
export const IN_STOCK_BADGE_THRESHOLD = 5;
export const LOW_INVENTORY_BADGE_THRESHOLD = 0;
export const useDeliveryMessage = (variant: Variant) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const deliveryMessageKey: string | null = useMemo(() => {
    const isOnStock = !!variant?.isOnStock;
    const isEcomSaleItem = !!variant?.isEcomSaleItem;
    const erpReprocurementTime = variant?.attributes['erp_reprocurement_time'] || 0;
    let message: string | null = null;

    if (isOnStock) {
      message = '1to3days';
    } else if (!isEcomSaleItem) {
      if (erpReprocurementTime === 111) {
        message = 'onRequest';
      } else if (erpReprocurementTime <= 1) {
        message = '1to3days';
      } else if (erpReprocurementTime <= 4) {
        message = '4to6days';
      } else if (erpReprocurementTime <= 6) {
        message = '7to8days';
      } else if (erpReprocurementTime <= 10) {
        message = '1to2weeks';
      } else if (erpReprocurementTime <= 18) {
        message = '3weeks';
      } else if (erpReprocurementTime >= 19) {
        message = 'over4weeks';
      }
    }
    return message;
  }, [variant]);

  const renderDeliveryMessage = (
    <>
      {deliveryMessageKey === 'onRequest' &&
        formatMessage({
          id: 'deliveryTime.onRequest',
          defaultMessage: 'Availability on request',
        })}
      {deliveryMessageKey === '1to3days' &&
        formatMessage({
          id: 'deliveryTime.1to3days',
          defaultMessage: 'Delivery within 1-3 workdays',
        })}
      {deliveryMessageKey === '4to6days' &&
        formatMessage({
          id: 'deliveryTime.4to6days',
          defaultMessage: 'Delivery expected in 4-6 workdays',
        })}
      {deliveryMessageKey === '7to8days' &&
        formatMessage({
          id: 'deliveryTime.7to8days',
          defaultMessage: 'Delivery expected in 7-8 workdays',
        })}
      {deliveryMessageKey === '1to2weeks' &&
        formatMessage({
          id: 'deliveryTime.1to2weeks',
          defaultMessage: 'Delivery expected in 1-2 weeks',
        })}
      {deliveryMessageKey === '3weeks' &&
        formatMessage({
          id: 'deliveryTime.3weeks',
          defaultMessage: 'Delivery expected in 3 weeks',
        })}
      {deliveryMessageKey === 'over4weeks' &&
        formatMessage({
          id: 'deliveryTime.over4weeks',
          defaultMessage: 'More than 6 weeks',
        })}
    </>
  );

  return { deliveryMessageKey, renderDeliveryMessage };
};
export default function ProductDetail({
  product,
  associatedVariantsProducts,
  associatedCompatibilityProducts,
  onAddToCart,
  onAddToWishlist,
  comparisonSkus,
  onToggleComparison,
  variant,
  quickBuyEnabled,
  minOrderAmount,
  maxOrderAmount,
  maxOrderAmountMargin,
  breadcrumbCategory,
  expertTeaser,
}: Props) {
  //next/router
  const router = useRouter();

  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatComparisonMessage } = useFormat({ name: 'comparison' });
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatContactformMessage } = useFormat({ name: 'contactform' });
  const { t } = useTranslation(['product']);
  const { deliveryMessageKey, renderDeliveryMessage } = useDeliveryMessage(variant);

  //Component states
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  const [orderQty, setOrderQty] = useState<number>(minOrderAmount);
  const [orderQtyRange, setOrderQtyRange] = useState<number[]>([minOrderAmount]);

  useEffect(() => {
    const qtys: number[] = [];
    for (let i = minOrderAmount; i <= maxOrderAmount; i++) {
      qtys.push(i);
    }
    setOrderQtyRange(qtys);
  }, [minOrderAmount, maxOrderAmount]);

  const handleOrderQtyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderQty(parseInt(e.target.value));
  };

  const handleAddToCart = useCallback(async () => {
    const qty = orderQty <= maxOrderAmountMargin ? orderQty : maxOrderAmountMargin;
    if (loading || (variant.isEcomSaleItem && !variant.isOnStock) || qty < 1) return;
    setLoading(true);
    await onAddToCart(variant, qty);
    setLoading(false);
    setAdded(true);
  }, [maxOrderAmountMargin, orderQty, loading, onAddToCart, variant]);

  const handleQuickBuy = useCallback(async () => {
    const qty = orderQty <= maxOrderAmountMargin ? orderQty : maxOrderAmountMargin;
    if (loading || (variant.isEcomSaleItem && !variant.isOnStock) || qty < 1) return;
    await onAddToCart(variant, qty);
    router.push('/checkout');
  }, [maxOrderAmountMargin, orderQty, loading, router, onAddToCart, variant]);

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  const detailsMarketing = useMemo(
    () => product.details.find((detail) => detail?.key === 'marketing_information'),
    [product.details],
  );
  const shortDescription = useMemo(() => {
    if (!detailsMarketing) {
      return undefined;
    }
    const item = detailsMarketing.items.find((item) => item.key === 'ecom_short_description');
    return item?.value;
  }, [detailsMarketing]);

  const detailsMain = useMemo(() => product.details.find((detail) => detail?.key === 'main'), [product.details]);
  const productSetInfo = useMemo(() => {
    if (!detailsMain) {
      return undefined;
    }
    const item = detailsMain.items.find((item) => item.key === 'ecom_set_information');
    return item?.value;
  }, [detailsMain]);

  const detailsSpecs = useMemo(() => {
    // remove all blacklisted specs
    const list = product.details.filter((detail) => !technicalDetailsAttributeGroupKeyBlacklist.includes(detail?.key));

    // create sorted list by given key list
    const sortedList = [];
    for (const value of technicalDetailsAttributeGroupByKey) {
      const entry = list.find((o) => o.key === value);
      if (entry) sortedList.push(entry);
    }

    // add all other entries which are not on the key list
    for (const value of list) {
      if (!technicalDetailsAttributeGroupByKey.includes(value.key)) sortedList.push(value);
    }

    return sortedList;
  }, [product.details]);

  // check for product youtube assets
  const detailsYoutubeAssets: UIAsset[] = useMemo(() => getAssets(variant.assets, 'video/mp4'), [variant.assets]);

  // check for product pdf assets
  const detailsPdfAssets: UIAsset[] = useMemo(() => getAssets(variant.assets, 'application/pdf'), [variant.assets]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [largeSwiper, setLargeSwiper] = useState(null);

  const associationSliderFixedMood: SliderProps = {
    slidesPerView: 3.2,
    spaceBetween: 20,
    breakpoints: {
      [sm]: {
        slidesPerView: 3.75,
      },
      [md]: {
        slidesPerView: 4.75,
      },
      [xl]: {
        slidesPerView: 2.5,
      },
      [xxl]: {
        slidesPerView: 3.75,
      },
    },
    scrollbar: false,
    arrows: false,
    dots: false,
  };

  const getImageThumb = (url: string, size: string) => {
    return url.replaceAll('200x200', size);
  };

  const getImageDefault = (url: string) => {
    return url.replace('thumbnail_200x200', 'default').replace('200x200', 'default');
  };

  const galleryImages = product.images ? getFiles(product.images) : [];

  const associationSliderConfiguration: SliderProps = associationSliderFixedMood;

  const [productsLightbox, setProductsLightbox] = useState(false);

  const lightboxSlides: Slide[] = useMemo(() => {
    const items: Slide[] = galleryImages.map((image) => ({
      type: 'image' as const,
      src: getImageDefault(image.src),
      title: '',
    }));
    /*
    items.push({
      type: 'video' as const,
      poster: ProductPlaceholder.src,
      sources: [
        {
          src: 'https://placehold.co/1280x720.mp4',
          type: 'video/mp4',
        },
      ],
      title: '',
    });
    */
    return items;
  }, [product]);

  /* OffCanvas for expert consultation */
  const [expertShow, setExpertShow] = useState(false);
  const [expertFormsDefaultActiveKey, setExpertFormsDefaultActiveKey] = useState<string>('');

  /* OffCanvas product compare */
  const [compareShow, setCompareShow] = useState(false);

  /* set active tab */
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (product?.description) {
      setActiveTab(1);
    } else if (detailsSpecs?.length > 0) {
      setActiveTab(0);
    } else if (associatedCompatibilityProducts && associatedCompatibilityProducts.length > 0) {
      setActiveTab(2);
    } else if (detailsYoutubeAssets.length > 0 || detailsPdfAssets.length > 0) {
      setActiveTab(3);
    }
  }, [detailsSpecs, product, associatedCompatibilityProducts, detailsYoutubeAssets, detailsPdfAssets]);

  /* detect touch device */
  const isTouchScreenDevice = useMemo(() => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  return (
    <>
      <div className="pb-40">
        <Breadcrumb className="breadcrumb-wrapper mob-c-c3 dt-c-c3">
          <Breadcrumb.Item linkAs={NextLink} href="/">
            Home
          </Breadcrumb.Item>
          {breadcrumbCategory?.ancestors?.map((ancestor, index) => (
            <Breadcrumb.Item key={'breadcrumb' + index} linkAs={NextLink} href={ancestor.url ?? ''}>
              {ancestor.name}
            </Breadcrumb.Item>
          ))}
          <Breadcrumb.Item linkAs={NextLink} href={breadcrumbCategory?.slug ?? ''}>
            {breadcrumbCategory?.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="row align-items-center justify-content-between">
          {/* DESKTOP ONLY */}
          <div className="col-8 d-none d-md-block">
            {/* TODO - Expert approved  */}
            <div className="expert-approved dt-p-p4 d-flex align-items-center">
              {variant?.attributes['expert_review_proved'] && (
                <>
                  <div className="align-items-center d-flex">
                    {IconCheckOutline({ className: 'h-px-16 text-secondary me-2' })}
                    {formatProductMessage({
                      id: 'expertApproved',
                      defaultMessage: 'Expert approved',
                    })}
                  </div>
                  {variant?.sku && <div className="expert-approved__spacer"></div>}
                </>
              )}
              {variant?.sku && (
                <div className="expert-approved__reference">
                  {formatProductMessage({
                    id: 'referenceNumber',
                    defaultMessage: 'Reference number',
                  })}
                  : {variant.sku}
                </div>
              )}
            </div>
          </div>
          {expertTeaser && (
            <div className="col-auto d-none d-md-block">
              <button className="btn btn-outline btn-img-thumb" onClick={() => setExpertShow(true)}>
                <span className="btn-img-thumb__thumb">
                  <Image
                    media={expertTeaser.v2image ? expertTeaser.v2image : expertTeaser.image}
                    layout="fill"
                    objectFit="cover"
                    alt={expertTeaser.v2image ? expertTeaser.v2image.media.name : expertTeaser.image.media.name}
                  />
                </span>
                {formatMessage({
                  id: 'consulting',
                  defaultMessage: 'Consulting',
                })}
                {IconChevronRight({})}
              </button>
            </div>
          )}
          {/* /DESKTOP ONLY */}

          {/* OffCanvas Expert content */}
          {expertTeaser && (
            <Offcanvas
              show={expertShow}
              onHide={() => {
                setExpertShow(false);
                setExpertFormsDefaultActiveKey('');
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {formatMessage({
                    id: 'consulting',
                    defaultMessage: 'Consulting',
                  })}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="bg-grey-10 p-20 rounded mb-20">
                  <div className="row align-items-center">
                    <div className="col-3">
                      <div className="ratio ratio-1x1">
                        <Image
                          media={expertTeaser.v3image ? expertTeaser.v3image : expertTeaser.image}
                          className="object-fit-cover rounded-circle"
                          layout="fill"
                          alt={expertTeaser.v3image ? expertTeaser.v3image.media.name : expertTeaser.image.media.name}
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="mob-p-p3 dt-p-3 fw-bold">
                        {expertTeaser.v3title ? expertTeaser.v3title : expertTeaser.title}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {expertTeaser.v3description ? expertTeaser.v3description : expertTeaser.description}
                </div>
                <ExpertContactForms
                  expertEmail={expertTeaser.email}
                  defaultActiveKey={expertFormsDefaultActiveKey}
                  contactContext={`product ref. ${variant.sku}`}
                />
              </Offcanvas.Body>
            </Offcanvas>
          )}
          {/* OffCanvas Expert content */}
        </div>
        <div className="pt-30">
          <div className="justify-content-between d-flex">
            <h1 className="h2 fw-bold mb-10 mb-md-30">{product?.name}</h1>
            {product?.brand && (
              <>
                {product.brand.imageSrc ? (
                  <NextLink href={brandRouteSlug + '/' + product.brand.key}>
                    <a className="d-none d-md-block h-px-30">
                      <picture className="d-block h-px-30">
                        <Image src={product.brand.imageSrc} alt={product.brand.name} className="h-100" />
                      </picture>
                    </a>
                  </NextLink>
                ) : (
                  <p className="mob-p-p3 dt-p-p3 m-0 fw-bold d-none d-md-block">
                    <NextLink href={brandRouteSlug + '/' + product.brand.key}>
                      <a className="link-reset">{product.brand.name}</a>
                    </NextLink>
                  </p>
                )}
              </>
            )}
          </div>

          <Row className="justify-content-between">
            {/* Image gallery */}
            <Col md={5} xl={7}>
              <div className="product-slider">
                {/* Thumbnails */}
                <div className="product-slider__thumbs d-none d-xl-block">
                  <Swiper
                    modules={[Thumbs, Navigation]}
                    onSwiper={setThumbsSwiper}
                    direction="vertical"
                    centeredSlides
                    centeredSlidesBounds
                    spaceBetween={10}
                    slidesPerView={4}
                    allowTouchMove={false}
                  >
                    {galleryImages.map((image) => (
                      <SwiperSlide key={image.id}>
                        <div className="slider__image">
                          <picture>
                            <Image src={image.src} alt="" />
                          </picture>
                        </div>
                      </SwiperSlide>
                    ))}
                    {/* VIDEO THUMBNAIL PLACEHOLDER
                    <SwiperSlide>
                      <div className="slider__image slider__image--video">
                        <picture>
                          <Image src={ProductPlaceholder.src} alt="Placeholder" />
                        </picture>
                      </div>
                    </SwiperSlide>
                    */}
                  </Swiper>
                  <div className="swiper-button-prev rounded" id="swiper-button-prev">
                    {IconChevronUp({})}
                  </div>
                  <div className="swiper-button-next rounded" id="swiper-button-next">
                    {IconChevronDown({})}
                  </div>
                </div>

                {/* Large Image */}
                <div className="product-slider__images">
                  <Swiper
                    modules={[Thumbs, Navigation, Pagination]}
                    thumbs={{ swiper: thumbsSwiper }}
                    onSwiper={setLargeSwiper}
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{ clickable: true }}
                    navigation={{
                      nextEl: '#swiper-button-next',
                      prevEl: '#swiper-button-prev',
                    }}
                    onSlideChange={() => {
                      thumbsSwiper.slideTo(largeSwiper.activeIndex);
                    }}
                  >
                    {galleryImages.map((image) => (
                      <SwiperSlide key={image.id}>
                        <div className="slider__image w-100">
                          <picture className="d-block w-100 pe-pointer">
                            <Image
                              src={getImageThumb(image.src, '800x800')}
                              alt=""
                              className="d-block w-100"
                              onClick={() => setProductsLightbox(true)}
                            />
                          </picture>
                        </div>
                      </SwiperSlide>
                    ))}
                    {/* VIDEO PLAYER PLACEHOLDER
                    <SwiperSlide>
                      <div className="slider__image">
                        <video controls controlsList="nodownload" className="d-block w-100">
                          <source src="https://placehold.co/1280x720.mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                     */}
                  </Swiper>

                  <Lightbox
                    open={productsLightbox}
                    close={() => setProductsLightbox(false)}
                    slides={lightboxSlides}
                    plugins={[Captions, Thumbnails, Video, Zoom]}
                    zoom={{
                      scrollToZoom: true,
                      maxZoomPixelRatio: 2,
                    }}
                    thumbnails={{
                      vignette: false,
                      gap: 20,
                    }}
                    render={{
                      slide: NextJsImage,
                      thumbnail: NextJsImage,
                      iconPrev: () => IconChevronLeft({}),
                      iconNext: () => IconChevronRight({}),
                    }}
                  />
                </div>
              </div>
            </Col>
            {/* /Image gallery */}

            {/* Product short info and interactions */}
            <Col md={6} xl={4}>
              <div className="product-info mt-20 mt-md-0">
                {/* MOBILE ONLY */}
                {product?.brand && (
                  <div className="d-md-none pb-10">
                    {product.brand.imageSrc ? (
                      <picture className="d-block h-px-16">
                        <Image src={product.brand.imageSrc} alt={product.brand.name} className="h-100" />
                      </picture>
                    ) : (
                      <p className="mob-p-p3 dt-p-p3 m-0 fw-bold">{product.brand.name}</p>
                    )}
                  </div>
                )}
                {/* MOBILE ONLY */}

                <h2 className="visually-hidden">
                  {formatProductMessage({ id: 'product.info', defaultMessage: 'Product information' })}
                </h2>

                {/* Prices / Discount / Stock */}

                {/* DESKTOP ONLY */}
                <div className="d-none d-md-flex align-items-center pb-1">
                  {product?.price &&
                    variant?.discountedPrice &&
                    product.price.centAmount > 0 &&
                    variant.discountedPrice.centAmount > 0 &&
                    variant.discountedPrice.centAmount < product.price.centAmount && (
                      <span className="label label-sm rounded-0 rounded-end bg-primary text-white">
                        -{Math.round((1 - variant.discountedPrice.centAmount / product.price.centAmount) * 100)}%
                      </span>
                    )}

                  {variant.discountedPrice && (
                    <Price
                      price={variant?.price}
                      className="text-decoration-line-through h5 text-grey-40 fw-normal m-0 ms-10"
                    />
                  )}
                </div>
                {/* /DESKTOP ONLY */}

                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="me-20 d-flex align-items-center flex-wrap">
                    {variant.discountedPrice && (
                      <Price price={variant?.discountedPrice} className="text-primary h1 mb-0 me-10" />
                    )}
                    {!variant.discountedPrice && <Price price={product?.price} className="h1 mb-0" />}

                    {/* MOBILE ONLY */}
                    {variant.discountedPrice && (
                      <Price
                        price={variant?.price}
                        className="text-decoration-line-through h5 text-grey-40 fw-normal m-0 d-md-none"
                      />
                    )}
                    {/* MOBILE ONLY */}
                  </div>

                  {/* MOBILE ONLY */}
                  {product?.price &&
                    variant?.discountedPrice &&
                    product.price.centAmount > 0 &&
                    variant.discountedPrice.centAmount > 0 &&
                    variant.discountedPrice.centAmount < product.price.centAmount && (
                      <span className="label label-sm me-n10 bg-primary text-white d-md-none">
                        -{Math.round((1 - variant.discountedPrice.centAmount / product.price.centAmount) * 100)}%
                      </span>
                    )}
                  {/* MOBILE ONLY */}

                  {/* Stock availability - ONLY DESKTOP */}
                  {variant?.isEcomSaleItem && (
                    <div className="d-none d-md-block">
                      {variant?.isOnStock &&
                        typeof variant?.availableQuantity === 'number' &&
                        variant.availableQuantity > IN_STOCK_BADGE_THRESHOLD && (
                          <div className="label bg-success">
                            {formatProductMessage({ id: 'inStock', defaultMessage: 'In Stock' })}
                          </div>
                        )}
                      {variant?.isOnStock &&
                        typeof variant?.availableQuantity === 'number' &&
                        variant.availableQuantity > LOW_INVENTORY_BADGE_THRESHOLD &&
                        variant.availableQuantity <= IN_STOCK_BADGE_THRESHOLD && (
                          <div className="label bg-warning">
                            {formatProductMessage({ id: 'lowInventory', defaultMessage: 'Low inventory' })}
                          </div>
                        )}
                      {(!variant?.isOnStock ||
                        (typeof variant?.availableQuantity === 'number' &&
                          variant.availableQuantity <= LOW_INVENTORY_BADGE_THRESHOLD)) && (
                        <div className="label bg-grey-40 text-grey-80">
                          {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
                        </div>
                      )}
                    </div>
                  )}
                  {/* Stock availability - ONLY DESKTOP */}
                </div>

                <div className="d-flex justify-content-between align-items-center pb-20">
                  {/* PRICE INCL. VAT */}
                  {product.grossPrice && (
                    <div className="mob-c-c4 dt-c-c4 m-0 text-grey-60 pt-10 fw-normal">
                      {formatProductMessage({ id: 'gross', defaultMessage: 'Gross' })}:{' '}
                      <Price price={product.grossPrice} className="d-inline" />
                    </div>
                  )}

                  {/* Stock availability - ONLY MOBILE */}
                  {variant?.isEcomSaleItem && (
                    <div className="d-md-none me-n10">
                      {variant?.isOnStock &&
                        typeof variant?.availableQuantity === 'number' &&
                        variant.availableQuantity > IN_STOCK_BADGE_THRESHOLD && (
                          <div className="label label-sm bg-success">
                            {formatProductMessage({ id: 'inStock', defaultMessage: 'In Stock' })}
                          </div>
                        )}
                      {variant?.isOnStock &&
                        typeof variant?.availableQuantity === 'number' &&
                        variant.availableQuantity > LOW_INVENTORY_BADGE_THRESHOLD &&
                        variant.availableQuantity <= IN_STOCK_BADGE_THRESHOLD && (
                          <div className="label label-sm bg-warning">
                            {formatProductMessage({ id: 'lowInventory', defaultMessage: 'Low inventory' })}
                          </div>
                        )}
                      {(!variant?.isOnStock ||
                        (typeof variant?.availableQuantity === 'number' &&
                          variant.availableQuantity <= LOW_INVENTORY_BADGE_THRESHOLD)) && (
                        <div className="label label-sm bg-grey-40 text-grey-80">
                          {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
                        </div>
                      )}
                    </div>
                  )}
                  {/* /Stock availability - ONLY MOBILE */}
                </div>

                {/* Important product facts */}
                {shortDescription && (
                  <div className="mob-p-p3 dt-p-p3 mb-20">
                    {/*<ul className="mb-0 list-styled">
                      <li>Sensor: Exmor RS CMOS 20.1 Mpix</li>
                      <li>Zeiss Vario-Sonnar T* lens: 24-70mm</li>
                      <li>SteadyShot optical image stabilisation</li>
                    </ul>*/}
                    <h3 className="visually-hidden">
                      {formatProductMessage({ id: 'product.desc', defaultMessage: 'Description' })}
                    </h3>
                    {parse(shortDescription)}
                  </div>
                )}

                {/* Delivery time */}
                {deliveryMessageKey !== null && (
                  <div className="pb-15 pb-md-20">
                    <h3 className="visually-hidden">
                      {formatProductMessage({ id: 'deliveryTime', defaultMessage: 'Delivery time' })}
                    </h3>

                    <div className="mob-p-p3 dt-p-p3 d-flex align-items-center">
                      {IconLocalShipping({ className: 'me-2 flex-shrink-0' })}
                      {renderDeliveryMessage}
                    </div>
                  </div>
                )}

                {/* Add to cart */}
                <form className="mt-10">
                  {(!variant?.isEcomSaleItem || variant?.isOnStock) && maxOrderAmountMargin < 1 && (
                    <Alert key="danger" variant="danger">
                      {t('maxOrderQtyReached', {
                        quantity: maxOrderAmount,
                      })}
                    </Alert>
                  )}
                  <div className="d-flex mb-15 mb-md-25">
                    {orderQtyRange.length > 1 && (
                      <select
                        id="amount"
                        name="amount"
                        className="form-select form-select-lg form-select--grey w-auto me-10"
                        value={orderQty}
                        onChange={handleOrderQtyChange}
                      >
                        {orderQtyRange.map((value) => (
                          <option key={'amount' + value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    )}
                    <div className="d-flex flex-fill">
                      {quickBuyEnabled && (
                        <button
                          type="button"
                          onClick={handleQuickBuy}
                          className="btn btn-primary btn-lg me-2"
                          disabled={
                            (variant.isEcomSaleItem && !variant.isOnStock) ||
                            !product?.price ||
                            maxOrderAmountMargin < 1
                          }
                        >
                          Buy Now
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={handleAddToCart}
                        className={clsx('btn btn-primary btn-lg flex-fill', !quickBuyEnabled ? '' : '')}
                        disabled={
                          (variant.isEcomSaleItem && !variant.isOnStock) || !product?.price || maxOrderAmountMargin < 1
                        }
                      >
                        {!loading && !added && (
                          <>
                            {!variant.isEcomSaleItem || variant.isOnStock
                              ? formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })
                              : formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
                          </>
                        )}
                        {loading && (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">
                              {formatMessage({
                                id: 'loading',
                                defaultMessage: 'Loading',
                              })}
                              ...
                            </span>
                          </div>
                        )}
                        {!loading && added && IconCheck({ className: 'icon-lg' })}
                      </button>
                    </div>
                  </div>
                </form>

                <div className="d-flex">
                  {/* TODO - Share */}
                  <Dropdown className="d-flex">
                    <Dropdown.Toggle as="div" className="icon-link mob-c-c2 dt-c-c2 me-15 me-xl-30">
                      {IconShare({})}
                      {formatMessage({
                        id: 'share',
                        defaultMessage: 'Share',
                      })}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key={'share-copy'}
                        onClick={() => {
                          navigator.clipboard.writeText(location.href);
                        }}
                      >
                        <div className="icon-link icon-link--hover-unset">
                          <div className="icon-link__icon-rounded">{IconCopy({})}</div>
                          {formatMessage({
                            id: 'copy.link',
                            defaultMessage: 'Copy link',
                          })}
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={'share-mail'}
                        href={`mailto:?subject=${encodeURI(product?.name)}&body=${encodeURI(window.location.href)}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="icon-link icon-link--hover-unset">
                          <div className="icon-link__icon-rounded">{IconMail({})}</div>
                          {formatMessage({
                            id: 'email',
                            defaultMessage: 'Email',
                          })}
                        </div>
                      </Dropdown.Item>

                      {isTouchScreenDevice && (
                        <Dropdown.Item
                          key={'share-whatsapp'}
                          href={`whatsapp://send?text=${encodeURI(window.location.href)}`}
                          data-action="share/whatsapp/share"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="icon-link icon-link--hover-unset">
                            <div className="icon-link__icon-rounded">
                              {IconWhatsapp({ className: 'text-whatsapp' })}
                            </div>
                            {formatMessage({
                              id: 'whatsapp',
                              defaultMessage: 'WhatsApp',
                            })}
                          </div>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <div
                    className="icon-link me-15 me-xl-30 mob-c-c2 dt-c-c2"
                    onClick={() => {
                      onToggleComparison(product.productId);
                    }}
                  >
                    {comparisonSkus?.includes(product.productId)
                      ? IconCompareArrows({ className: 'text-primary' })
                      : IconCompareArrows({})}
                    {formatComparisonMessage({
                      id: 'comparison.compare',
                      defaultMessage: 'Compare',
                    })}
                  </div>

                  {/* TODO - OffCanvas products compare */}
                  <Offcanvas show={compareShow} onHide={() => setCompareShow(false)} placement={'end'}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        {formatComparisonMessage({
                          id: 'comparison.title',
                          defaultMessage: 'Comparison',
                        })}
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      {/* Loop for each product */}
                      <div className="row pb-20">
                        <div className="col-3">
                          <NextLink href="#">
                            <div className="bg-white p-10 rounded border pe-pointer">
                              <Image
                                layout="fill"
                                src={product?.images[0]?.src}
                                className="object-fit-cover"
                                alt={product?.name}
                              />
                            </div>
                          </NextLink>
                        </div>
                        <div className="col-7">
                          <div className="mob-p-p3 dt-p-p3">{product?.name}</div>
                        </div>
                        <div className="col-2 text-end">
                          <button
                            className="btn-reset icon-link"
                            onClick={() => {
                              onToggleComparison(product.productId);
                            }}
                          >
                            {IconDelete({})}
                          </button>
                        </div>
                      </div>
                      {/* /Loop for each product */}
                      <NextLink href="/">
                        <a className="btn btn-primary w-100 mb-20">
                          {formatComparisonMessage({
                            id: 'comparison.compare',
                            defaultMessage: 'Compare',
                          })}
                        </a>
                      </NextLink>
                      <div className="text-center">
                        <button className="btn-reset icon-link mob-p-p3 dt-p-p3">
                          {IconDelete({ className: 'me-2' })}
                          {formatMessage({
                            id: 'delete.all',
                            defaultMessage: 'Delete all',
                          })}
                        </button>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                  {/* /OffCanvas products compare */}

                  <div className="icon-link mob-c-c2 dt-c-c2" onClick={onAddToWishlist}>
                    {product?.isOnWishlist ? IconHeartFilled({ className: 'text-primary' }) : IconHeart({})}
                    {formatWishlistMessage({
                      id: 'wishlist.favorite',
                      defaultMessage: 'Favorite',
                    })}
                  </div>
                </div>

                {/* Associated product variants */}
                {associatedVariantsProducts && associatedVariantsProducts.length > 0 && (
                  <>
                    <h4 className="dt-heading-h4 pt-30 pb-10">
                      {formatProductMessage({
                        id: 'connected.products',
                        defaultMessage: 'Connected products',
                      })}
                    </h4>
                    <Slider {...associationSliderConfiguration} className="associated-products-slider">
                      {associatedVariantsProducts.map((associatedProduct, index) => {
                        const imageSrc =
                          associatedProduct.images && associatedProduct.images.length > 0
                            ? getImageThumb(getFiles(associatedProduct.images, { number: 0 }).src, '400x400')
                            : ProductPlaceholder.src;
                        return (
                          <div key={'associatedVariantsProducts' + index} className="product-card-simple">
                            <div className="product-card-simple__img ratio ratio-1x1">
                              <Image
                                layout="fill"
                                src={imageSrc}
                                alt={associatedProduct.name}
                                className="object-fit-cover"
                              />
                            </div>
                            <NextLink href={associatedProduct._url || ''}>
                              <a
                                className={clsx(
                                  'product-card-simple__link',
                                  associatedProduct.isEcomSaleItem && !associatedProduct.isOnStock && 'opacity-50',
                                )}
                              >
                                {associatedProduct.name}
                              </a>
                            </NextLink>
                          </div>
                        );
                      })}
                    </Slider>
                  </>
                )}
              </div>

              {/* MOBILE ONLY */}
              {expertTeaser && (
                <button
                  className="btn btn-outline btn-img-thumb d-md-none w-100 mt-10"
                  onClick={() => setExpertShow(true)}
                >
                  <span className="btn-img-thumb__thumb">
                    <Image
                      media={expertTeaser.v2image ? expertTeaser.v2image : expertTeaser.image}
                      layout="fill"
                      objectFit="cover"
                      alt={expertTeaser.v2image ? expertTeaser.v2image.media.name : expertTeaser.image.media.name}
                    />
                  </span>
                  {formatMessage({
                    id: 'consulting',
                    defaultMessage: 'Consulting',
                  })}
                  {IconChevronRight({})}
                </button>
              )}
              <div className="expert-approved mob-p-p4 dt-p-p4 d-flex align-items-center justify-content-between flex-wrap pt-15 d-md-none">
                <div className="align-items-center d-flex pt-10">
                  {IconCheckOutline({ className: 'h-px-16 text-secondary me-2' })}
                  {formatProductMessage({
                    id: 'expertApproved',
                    defaultMessage: 'Expert approved',
                  })}
                </div>
                {variant?.sku && (
                  <div className="pt-10">
                    {formatProductMessage({
                      id: 'referenceNumber',
                      defaultMessage: 'Ref.',
                    })}
                    : {variant.sku}
                  </div>
                )}
              </div>
              {/* /MOBILE ONLY */}
            </Col>
            {/* /Product short info and interactions */}
          </Row>
        </div>
      </div>

      {/* Detailed product information */}
      <div className="bg-grey-full section-spacing-padding-bottom">
        {(detailsSpecs?.length > 0 ||
          product?.description ||
          (associatedCompatibilityProducts && associatedCompatibilityProducts.length > 0) ||
          detailsYoutubeAssets.length > 0 ||
          detailsPdfAssets.length > 0) && (
          <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <div className="d-flex justify-content-center pt-40">
              <Nav variant="tabs">
                {product?.description && (
                  <Nav.Item>
                    <Nav.Link eventKey={1}>
                      {formatProductMessage({ id: 'product.details', defaultMessage: 'Product details' })}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {detailsSpecs?.length > 0 && (
                  <Nav.Item>
                    <Nav.Link eventKey={0}>
                      {formatProductMessage({ id: 'technical.info', defaultMessage: 'Technical information' })}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {associatedCompatibilityProducts && associatedCompatibilityProducts.length > 0 && (
                  <Nav.Item>
                    <Nav.Link eventKey={2}>
                      {formatProductMessage({ id: 'compatibility', defaultMessage: 'Compatibility' })}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {(detailsYoutubeAssets.length > 0 || detailsPdfAssets.length > 0) && (
                  <Nav.Item>
                    <Nav.Link eventKey={3}>
                      {formatProductMessage({ id: 'media.downloads', defaultMessage: 'Media & Downloads' })}
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>
            <div className="bg-white p-20 rounded border border-grey-20">
              <Row>
                <Col xl={productSetInfo || expertTeaser ? 7 : 12}>
                  <div className="bg-grey-10 p-20 rounded">
                    <Tab.Content>
                      {product?.description && <Tab.Pane eventKey={1}>{parse(product.description)}</Tab.Pane>}
                      {detailsSpecs?.length > 0 && (
                        <Tab.Pane eventKey={0}>
                          <Accordion
                            className="accordion--alternative"
                            defaultActiveKey={technicalDetailsAttributeActiveByKey}
                            alwaysOpen
                          >
                            {detailsSpecs.map((detailsSpec, index) => (
                              <Accordion.Item key={detailsSpec.key} eventKey={detailsSpec.key}>
                                <Accordion.Header className="mob-p-p2 dt-p-p2">{detailsSpec.name}</Accordion.Header>
                                <Accordion.Body className="last-child-no-mb">
                                  <dl className="dl-styled dl-styled--show-all">
                                    {/* Only show the first 5 entries */}
                                    {detailsSpec.items?.slice(0, 5).map((item, index) => (
                                      <div
                                        key={'tab-technical-item' + index}
                                        className="dl-styled__item mob-p-p4 dt-p-3"
                                      >
                                        <dt>{item?.label}</dt>
                                        <dd>{parse(item?.value)}</dd>
                                      </div>
                                    ))}
                                    {/* Show all other entries */}
                                    {detailsSpec.items?.slice(5).length > 0 && (
                                      <Accordion className="accordion--small-btn-style pt-6">
                                        <Accordion.Item
                                          eventKey={'tab-technical-acc-item' + index}
                                          key={'tab-technical-acc-item' + index}
                                        >
                                          <Accordion.Body className="pt-0">
                                            {detailsSpec.items?.slice(5).map((item, index) => (
                                              <div
                                                key={'tab-technical-acc-item-entry' + index}
                                                className="dl-styled__item"
                                              >
                                                <dt>{item?.label}</dt>
                                                <dd>{parse(item?.value)}</dd>
                                              </div>
                                            ))}
                                          </Accordion.Body>
                                          <Accordion.Header className="mob-p-p2 dt-p-p2 pt-6">
                                            {formatProductMessage({
                                              id: 'technical.more',
                                              defaultMessage: 'Show more',
                                            })}
                                          </Accordion.Header>
                                        </Accordion.Item>
                                      </Accordion>
                                    )}
                                  </dl>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </Tab.Pane>
                      )}
                      {associatedCompatibilityProducts && associatedCompatibilityProducts.length > 0 && (
                        <Tab.Pane eventKey={2}>
                          <div className="row">
                            {associatedCompatibilityProducts.map((associatedProduct, index) => {
                              const imageSrc =
                                associatedProduct.images && associatedProduct.images.length > 0
                                  ? getImageThumb(getFiles(associatedProduct.images, { number: 0 }).src, '400x400')
                                  : ProductPlaceholder.src;
                              return (
                                <div
                                  key={'associatedCompatibilityProduct' + index}
                                  className="col-12 col-sm-6 col-md-4 col-xl-6 col-xxl-4 d-flex mb-20"
                                >
                                  <div className="product-card position-relative flex-grow-1">
                                    <div className="product-card__image p-20 pb-0">
                                      <Image
                                        layout="fill"
                                        src={imageSrc}
                                        alt={associatedProduct.name}
                                        className="object-fit-cover"
                                      />
                                    </div>
                                    <div className="product-card__text">
                                      <div className="product-card__name mob-c-c3 dt-c-c3 mb-2">
                                        {associatedProduct.name}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        {associatedProduct.discountedPrice && (
                                          <Price
                                            price={associatedProduct.discountedPrice}
                                            className="me-10 text-primary mob-h-h5 dt-h-h5"
                                          />
                                        )}
                                        <Price
                                          price={associatedProduct.price}
                                          className={classnames(
                                            associatedProduct.discountedPrice
                                              ? 'text-decoration-line-through ms-1 mob-p-p3 dt-p-p3 text-grey-40'
                                              : 'mob-h-h5 dt-h-h5',
                                          )}
                                        />
                                      </div>
                                    </div>
                                    {((associatedProduct.discountedPrice &&
                                      (!associatedProduct.isEcomSaleItem || associatedProduct.isOnStock)) ||
                                      (associatedProduct.isEcomSaleItem && !associatedProduct.isOnStock)) && (
                                      <div className="product-card__labels">
                                        {associatedProduct.discountedPrice &&
                                          (!associatedProduct.isEcomSaleItem || associatedProduct.isOnStock) && (
                                            <div className="label bg-primary text-white mb-2">
                                              {IconFlash({})}
                                              {associatedProduct.price &&
                                                associatedProduct.discountedPrice &&
                                                associatedProduct.price.centAmount > 0 &&
                                                associatedProduct.discountedPrice.centAmount > 0 &&
                                                associatedProduct.discountedPrice.centAmount <
                                                  associatedProduct.price.centAmount && (
                                                  <span>
                                                    -
                                                    {Math.round(
                                                      (1 -
                                                        associatedProduct.discountedPrice.centAmount /
                                                          associatedProduct.price.centAmount) *
                                                        100,
                                                    )}
                                                    %
                                                  </span>
                                                )}
                                            </div>
                                          )}
                                        {associatedProduct.isNew && associatedProduct.isOnStock && (
                                          <div className="label bg-primary text-white mb-2 text-uppercase">
                                            {formatProductMessage({
                                              id: 'new',
                                              defaultMessage: 'New',
                                            })}
                                          </div>
                                        )}
                                        {associatedProduct.isEcomSaleItem && !associatedProduct.isOnStock && (
                                          <div className="label bg-grey-40 text-grey-80 mb-2">
                                            {formatProductMessage({
                                              id: 'outOfStock',
                                              defaultMessage: 'Out of stock',
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <NextLink href={associatedProduct._url || ''}>
                                      <a
                                        className={clsx(
                                          'product-card__link',
                                          associatedProduct.isEcomSaleItem &&
                                            !associatedProduct.isOnStock &&
                                            'opacity-50',
                                        )}
                                      >
                                        <span className="visually-hidden">
                                          {formatProductMessage({
                                            id: 'viewProduct',
                                            defaultMessage: 'View product',
                                          })}
                                        </span>
                                      </a>
                                    </NextLink>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Tab.Pane>
                      )}
                      {(detailsYoutubeAssets.length > 0 || detailsPdfAssets.length > 0) && (
                        <Tab.Pane eventKey={3}>
                          <Accordion className="accordion--alternative" defaultActiveKey="youtubeAssets" alwaysOpen>
                            {detailsYoutubeAssets.length > 0 && (
                              <>
                                <h4 className="mob-p-p2 dt-p-p2 border-bottom border-grey-100 fw-bold pb-6 mb-20">
                                  {formatProductMessage({ id: 'media', defaultMessage: 'Media' })}
                                </h4>
                                <div className="last-child-no-mb">
                                  <div className="row">
                                    {/* TODO: Group by tags */}
                                    {detailsYoutubeAssets
                                      .sort((assetA, assetB) =>
                                        assetA.tags[0] > assetB.tags[0] ? 1 : assetB.tags[0] > assetA.tags[0] ? -1 : 0,
                                      )
                                      .map((asset, index) => (
                                        <div className="col-12 col-sm-6" key={`${asset.key}${index}`}>
                                          <div className="mb-4">
                                            <MediaYoutube youtubeId={asset.source.uri} width="w100" alignment="bl" />
                                            <div className="mob-p-p3 dt-p-p3 fw-bold mt-2">{asset.name}</div>
                                            {asset.description && (
                                              <div className="mob-p-p4 dt-p-p4">{asset.description}</div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </>
                            )}
                            {detailsPdfAssets.length > 0 && (
                              <>
                                <h4 className="mob-p-p2 dt-p-p2 border-bottom border-grey-100 fw-bold pb-6 mb-20">
                                  {formatProductMessage({ id: 'downloads', defaultMessage: 'Downloads' })}
                                </h4>
                                <div className="last-child-no-mb">
                                  {/* TODO: Group by tags */}
                                  {detailsPdfAssets
                                    .sort((assetA, assetB) =>
                                      assetA.tags[0] > assetB.tags[0] ? 1 : assetB.tags[0] > assetA.tags[0] ? -1 : 0,
                                    )
                                    .map((asset, index) => (
                                      <div className="mb-3" key={`${asset.key}${index}`}>
                                        <a
                                          href={asset.source.uri}
                                          target="_blank"
                                          className="d-inline-flex link-reset"
                                          rel="noreferrer"
                                        >
                                          {IconPdf({ className: 'dt-p-p1 me-2 text-primary' })}
                                          <span className="mob-p-p3 dt-p-p3 fw-bold">{asset.name}</span>
                                        </a>
                                        {asset.description && (
                                          <div className="mob-p-p4 dt-p-p4">{asset.description}</div>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              </>
                            )}
                          </Accordion>
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </div>
                </Col>
                {(productSetInfo || expertTeaser) && (
                  <Col xl={5} className="mt-20 mt-xl-0">
                    {productSetInfo && (
                      <div className="bg-grey-10 p-20 rounded mb-20">
                        <p className="mob-p-p2 dt-p-p2 fw-bold">
                          {formatProductMessage({ id: 'in.the.box', defaultMessage: 'In the box' })}
                        </p>
                        <div className="mob-p-p3 dt-p-p3">{parse(productSetInfo)}</div>
                      </div>
                    )}
                    {expertTeaser && (
                      <div className="bg-grey-10 p-20 rounded">
                        <p className="mob-p-p2 dt-p-p2 fw-bold">
                          {formatProductMessage({ id: 'expertTeaser.title', defaultMessage: 'Ask our experts' })}
                        </p>
                        <p className="mob-p-p3 dt-p-p3">
                          {formatProductMessage({
                            id: 'expertTeaser.description',
                            defaultMessage:
                              'You have questions about this product? Our expert can help you. Our consulting service is available from MO to FR from 09:00 - 18:00.',
                          })}
                        </p>
                        <div className="d-flex align-items-center pb-20">
                          {(expertTeaser.v2image || expertTeaser.image) && (
                            <div className="h-px-75 w-px-75 flex-shrink-0 rounded-circle position-relative overflow-hidden me-20">
                              <Image
                                media={expertTeaser.v2image ? expertTeaser.v2image : expertTeaser.image}
                                className="object-fit-cover "
                                layout="fill"
                                alt={
                                  expertTeaser.v2image ? expertTeaser.v2image.media.name : expertTeaser.image.media.name
                                }
                              />
                            </div>
                          )}
                          <div>
                            {(expertTeaser.v2title || expertTeaser.title) && (
                              <div className="mob-p-p3 dt-p-p3 fw-bold py-10">
                                {expertTeaser.v2title ? expertTeaser.v2title : expertTeaser.title}
                              </div>
                            )}
                            {(expertTeaser.v2description || expertTeaser.description) && (
                              <div className="mob-p-p4 dt-p-p4 pb-10">
                                {expertTeaser.description ? expertTeaser.v2description : expertTeaser.description}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-12">
                            <button
                              className="btn w-100 btn-primary mb-10 mb-xl-20 btn-sm btn-lg-md"
                              onClick={() => {
                                setExpertFormsDefaultActiveKey('callback');
                                setExpertShow(true);
                              }}
                            >
                              {formatContactformMessage({
                                id: 'request.callback',
                                defaultMessage: 'Request a callback',
                              })}
                            </button>
                          </div>
                          <div className="col-12 col-md-6 col-xl-12">
                            <button
                              className="btn w-100 btn-secondary btn-sm btn-lg-md"
                              onClick={() => {
                                setExpertFormsDefaultActiveKey('contact');
                                setExpertShow(true);
                              }}
                            >
                              {formatContactformMessage({
                                id: 'write.message',
                                defaultMessage: 'Write a message',
                              })}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            </div>
          </Tab.Container>
        )}
      </div>
    </>
  );
}
