import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ProductWithAttributeGroups } from '@Types/product/Product';
import classnames from 'classnames';
import parse from 'html-react-parser';
import debounce from 'lodash.debounce';
import { useEventListener } from 'usehooks-ts';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import { lg, sm, xl } from 'helpers/utils/screensizes';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import { useComparison, useSearch } from '../../../../frontastic';
import { TrackEventKey } from '../../../../frontastic/provider/frontastic/UseSearch';
import { gtmPushItemEvent } from '../../../../frontastic/tastics/snaq/products/details';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import Slider, { SliderProps } from '../../slider';
import ProductCard from '../card';
import { technicalDetailsAttributeGroupKeyBlacklist, UIDetail, UIDetailItem, UIProduct } from '../product-details';

const sliderFixedMood: SliderProps = {
  slidesPerView: 1,
  breakpoints: {
    [sm]: {
      slidesPerView: 2,
    },
    [lg]: {
      slidesPerView: 3,
    },
    [xl]: {
      slidesPerView: 4,
    },
  },
  arrows: false,
  dots: false,
};

const sliderConfiguration: SliderProps = sliderFixedMood;

interface Props {
  products: ProductWithAttributeGroups[];
  compareSkus: string[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const ProductsComparison: React.FC<Props> = ({ products, compareSkus, background, innerSpacing, outerSpacing }) => {
  const { formatMessage: formatComparisonMessage } = useFormat({ name: 'comparison' });

  const [isLG] = useMediaQuery(lg);

  const { push, query } = useRouter();
  const { data: comparisonSkus, removeComparisonSku } = useComparison();

  const prods = useMemo<UIProduct[]>(
    () =>
      products.map((productItem) => {
        const { product, productAttributeGroups } = productItem;

        const currentVariantIdx = product?.variants.findIndex(({ sku }) => compareSkus.includes(sku) ?? 0);
        const variant = product?.variants[currentVariantIdx];

        const detailItems: UIDetail[] = [];

        variant?.attributeLabels.forEach((attributeLabel) => {
          const attributeKey = attributeLabel.key;
          const label = attributeLabel.label ?? null;
          if (typeof variant?.attributes[attributeKey] !== 'string') return;
          let displayValue = null;
          try {
            const valueObj = JSON.parse(variant.attributes[attributeKey]);
            const hasAmount = !!valueObj.amount;
            const hasUnit = !!valueObj.unit;
            if (hasAmount && hasUnit) {
              const unitString = String(valueObj.unit);
              displayValue = `${parseInt(valueObj.amount)} ${
                unitString.charAt(0).toUpperCase() + unitString.slice(1).toLowerCase().replaceAll('_', ' ')
              }`;
            } else {
              displayValue = variant.attributes[attributeKey];
            }
          } catch (e) {
            displayValue = variant.attributes[attributeKey];
          }
          if (!label || !displayValue) return;
          productAttributeGroups.forEach((group) => {
            const attributeItem = group.attributes.find((attribute) => attribute.key === attributeKey);
            if (attributeItem) {
              const detailGroupIndex = detailItems.findIndex((detail) => detail.name === group.name);
              let detailGroup: UIDetail;
              if (detailGroupIndex > -1) {
                detailGroup = detailItems.splice(detailGroupIndex, 1).shift();
              } else {
                detailGroup = {
                  key: group.key,
                  name: group.name,
                  items: [],
                };
              }
              detailGroup.items.push({
                key: attributeKey,
                label: label,
                value: displayValue,
              });
              detailItems.push(detailGroup);
            }
          });
        });
        return {
          productId: product?.productId,
          name: product?.name,
          _url: product?._url,
          // add variants as well, so we can select and filter
          variants: product?.variants,
          price: variant?.price,
          // rating: 4,
          images: variant?.images?.map((img: string, id: number) => ({
            id: `${variant?.sku}-${id}`,
            src: img,
            alt: variant?.sku,
          })),
          description: product?.description || '',
          details: detailItems.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          brand: product?.brand,
          isNew: product?.isNew,
        };
      }),
    [products, compareSkus],
  );

  const comparisonSpecs = useMemo<
    {
      key: string;
      label: string;
    }[]
  >(() => {
    const specs: {
      key: string;
      label: string;
    }[] = [];
    prods.forEach((prod) => {
      const list = prod.details.filter((detail) => !technicalDetailsAttributeGroupKeyBlacklist.includes(detail?.key));

      list.forEach((detail) => {
        const items = detail.items?.filter((item) => !specs.some((spec) => spec.key === item.key)) ?? [];
        items.forEach((item) => specs.push({ key: item.key, label: item.label }));
      });
    });
    return specs;
  }, [prods]);

  const { trackEvent } = useSearch();
  const handleTracking = useCallback(
    (id, title, index) => {
      if (!id) {
        return;
      }
      try {
        trackEvent(TrackEventKey.click, [
          {
            id: id,
            title: title,
            query: 'comparison',
            pos: index,
            page: 1,
            pageSize: prods.length,
          },
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    [trackEvent, prods],
  );

  // GTM event tracking
  const [gtmEventPushed, setGtmEventPushed] = useState<boolean>(false);
  useEffect(() => {
    setGtmEventPushed(false);
  }, [query]);
  useEffect(() => {
    if (gtmEventPushed || !prods) {
      return;
    }
    setGtmEventPushed(true);
    const items = [];
    if (prods && prods.length > 0) {
      prods.forEach((prod) => {
        const currentVariantIdx = prod?.variants.findIndex(({ sku }) => compareSkus.includes(sku) ?? 0);
        const item = {
          item_name: prod.name,
          item_id: prod.variants[currentVariantIdx].sku,
          quantity: '1',
        };
        if ('brand' in prod) {
          item['item_brand'] = prod.brand.name;
        }
        if (prod.discountedPrice) {
          item['price'] = (prod.discountedPrice.centAmount / 100).toFixed(2);
        } else if (prod.price) {
          item['price'] = (prod.price.centAmount / 100).toFixed(2);
        }
        items.push(item);
      });
    }
    if (process.env.NODE_ENV === 'production' && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'compare_products',
        ecommerce: {
          items: items,
        },
      });
    } else {
      console.info('GtmEvent compare_products items', items);
    }
  }, [gtmEventPushed, prods, compareSkus]);

  // const specLabelsHeaderRefs = useRef<HTMLDivElement | null>(null);
  // https://stackoverflow.com/a/70556386
  const cardHeaderRefs = useRef<(HTMLDivElement | null)[]>([]);
  const toolbarRefs = useRef<(HTMLDivElement | null)[]>([]);
  const specItemsRefs = useRef<(HTMLDivElement | null)[][]>([]);

  const [cardHeaderHeight, setCardHeaderHeight] = useState<number>();
  const [toolbarHeight, setToolbarHeight] = useState<number>();
  const [specRowHeights, setSpecRowHeights] = useState<number[]>([]);

  // https://dmitripavlutin.com/react-throttle-debounce/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResize = useCallback(
    debounce(() => {
      let cardHeaderH = 0;
      cardHeaderRefs.current.forEach((header) => {
        const height = header?.clientHeight;
        if (height && cardHeaderH < height) {
          cardHeaderH = height;
        }
      });
      setCardHeaderHeight(cardHeaderH ?? 0);
      let toolbarH = 0;
      toolbarRefs.current.forEach((header) => {
        const height = header?.clientHeight;
        if (height && toolbarH < height) {
          toolbarH = height;
        }
      });
      setToolbarHeight(toolbarH ?? 0);
      const specItemsH = [];
      specItemsRefs.current.forEach((column, columnIndex) => {
        column.forEach((row, rowIndex) => {
          if (!isLG && columnIndex === 0) {
            return;
          }
          const height = row?.clientHeight;
          if (height && (!specItemsH[rowIndex] || specItemsH[rowIndex] < height)) {
            specItemsH[rowIndex] = height;
          }
        });
      });
      setSpecRowHeights([...specItemsH]);
    }, 600),
    [isLG, cardHeaderRefs.current, specItemsRefs.current],
  );

  useEffect(() => {
    debouncedResize();
    return () => {
      debouncedResize.cancel();
    };
  }, []);

  useEventListener('resize', () => {
    setCardHeaderHeight(0);
    setToolbarHeight(0);
    setSpecRowHeights([]);
    debouncedResize();
  });

  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <h1 className="mb-20">
        {formatComparisonMessage({
          id: 'comparison.title',
          defaultMessage: 'Comparison',
        })}
      </h1>
      {prods.length === 0 ? (
        <p>
          {formatComparisonMessage({
            id: 'comparison.noproducts',
            defaultMessage: 'No products found for comparison',
          })}
          .
        </p>
      ) : (
        <Slider {...sliderConfiguration} className="swiper--mobile-full">
          {isLG && comparisonSpecs.length > 0 && (
            <div className="bg-grey-10 rounded p-20 w-100">
              <div
                className="d-flex"
                ref={(el) => {
                  cardHeaderRefs.current[0] = el;
                }}
                style={{ minHeight: cardHeaderHeight ? `${cardHeaderHeight}px` : 0 }}
              />
              <div
                className="d-flex my-20"
                ref={(el) => {
                  toolbarRefs.current[0] = el;
                }}
                style={{ minHeight: toolbarHeight ? `${toolbarHeight}px` : 0 }}
              />
              <dl className="dl-styled">
                {comparisonSpecs.map((spec, specIndex) => {
                  return (
                    <div
                      key={'tab-technical-item0' + specIndex}
                      className="dl-styled__item"
                      ref={(el) => {
                        specItemsRefs.current[0] = specItemsRefs.current[0] || [];
                        specItemsRefs.current[0][specIndex] = el;
                      }}
                      style={{ minHeight: specRowHeights[specIndex] ? `${specRowHeights[specIndex]}px` : 0 }}
                    >
                      <dt className="text-end w-100">{spec.label}</dt>
                    </div>
                  );
                })}
              </dl>
            </div>
          )}
          {prods.map((prod, prodIndex) => {
            const detailsSpecs = prod.details.filter(
              (detail) => !technicalDetailsAttributeGroupKeyBlacklist.includes(detail?.key),
            );
            return (
              <div key={prod.productId} className="bg-grey-10 rounded p-20">
                <div
                  className="d-flex"
                  ref={(el) => {
                    cardHeaderRefs.current[prodIndex] = el;
                  }}
                  style={{ minHeight: cardHeaderHeight ? `${cardHeaderHeight}px` : 0 }}
                >
                  <ProductCard
                    imageSrc={
                      getImageThumb(getFiles(prod.variants[0].images, { number: 0, srcKey: '' }), '400x400') ||
                      ProductPlaceholder.src
                    }
                    imageAlt={prod.name}
                    title={prod.name}
                    price={prod.variants[0].price}
                    discountedPrice={prod.variants[0].discountedPrice}
                    isOnStock={!prod.variants[0].isEcomSaleItem || !!prod.variants[0].isOnStock}
                    isNew={prod.isNew}
                    link={prod._url}
                    trackingHandler={() => handleTracking(prod.productId, prod.name, prodIndex + 1)}
                  />
                </div>
                <div
                  className="d-flex my-20"
                  ref={(el) => {
                    toolbarRefs.current[prodIndex] = el;
                  }}
                  style={{ minHeight: toolbarHeight ? `${toolbarHeight}px` : 0 }}
                >
                  <NextLink href={prod._url || ''}>
                    <a
                      className="btn btn-primary btn-sm"
                      onClick={() => handleTracking(prod.productId, prod.name, prodIndex + 1)}
                    >
                      {formatComparisonMessage({
                        id: 'comparison.goto.products',
                        defaultMessage: 'Go to product',
                      })}
                    </a>
                  </NextLink>
                  <div
                    className="btn btn-secondary btn-sm ms-2"
                    onClick={() => {
                      const currentVariantIdx = prod?.variants.findIndex(({ sku }) => compareSkus.includes(sku) ?? 0);
                      removeComparisonSku(prod.productId);
                      gtmPushItemEvent('remove_from_comparison', prod, prod.variants[currentVariantIdx]);
                      push({
                        query: {
                          ...query,
                          skus: encodeURI(comparisonSkus?.filter((sku) => sku !== prod.productId).join(',') ?? ''),
                        },
                      });
                    }}
                  >
                    {formatComparisonMessage({
                      id: 'comparison.remove',
                      defaultMessage: 'Remove item from comparison',
                    })}
                  </div>
                </div>
                {comparisonSpecs.length > 0 && (
                  <dl className="dl-styled">
                    {comparisonSpecs.map((spec, specIndex) => {
                      let detailsSpecsItem: UIDetailItem | null = null;
                      detailsSpecs?.every((detail) => {
                        const item = detail.items.find((item) => item.key === spec.key);
                        if (item) {
                          detailsSpecsItem = item;
                          return false;
                        } else {
                          return true;
                        }
                      });
                      return (
                        <div
                          key={'tab-technical-item' + prodIndex + specIndex}
                          className={classnames('dl-styled__item', !isLG && 'd-block')}
                          ref={(el) => {
                            specItemsRefs.current[prodIndex + 1] = specItemsRefs.current[prodIndex + 1] || [];
                            specItemsRefs.current[prodIndex + 1][specIndex] = el;
                          }}
                          style={{ minHeight: specRowHeights[specIndex] ? `${specRowHeights[specIndex]}px` : 0 }}
                        >
                          <dt className={classnames(isLG && 'visually-hidden')}>{spec.label}</dt>
                          <dd>{parse(detailsSpecsItem?.value ?? '-')}</dd>
                        </div>
                      );
                    })}
                  </dl>
                )}
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default ProductsComparison;
